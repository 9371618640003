import React from 'react';

import Header from './Header';
import Background from '../assets/Scheinwerfer.jpg';
import Deutsch from '../assets/Fahnen/Deutsch.jpg';
import UK from '../assets/Fahnen/UK.jpg';
import Spanisch from '../assets/Fahnen/Spanisch.jpg';
import Kroatisch from '../assets/Fahnen/Kroatisch.jpg';
import Rumaenisch from '../assets/Fahnen/Rumaenisch.jpg';
import Russisch from '../assets/Fahnen/Russisch.jpg';

const International = () => {
  return (
    <div>
      <Header backgroundimage={Background}>
        <p className="Header-text">
          +49 69 95 92 80 13
          <br />
          E-MAIL: INFO@PILATUS.DE
        </p>
      </Header>
      <div className="container-fluid International-cont border-bottom">
        <div className="container">
          {/* GERMAN */}
          <div className="row pt-5 pb-5">
            {/* > XS */}
            <div className="col d-none d-md-block">
              <div className="media">
                <img src={Deutsch} className="mr-5" alt="Flagge Deutschland" />
                <div className="media-body">
                  <div className="row">
                    <div className="col">
                      <p>Wir verkaufen an:</p>
                      <hr />
                      <p>Firmen innerhalb der EU (netto)</p>
                      <p>
                        Privatpersonen innerhalb der EU (plus Mehrwertsteuer)
                      </p>
                      <p>
                        Firmen und Privatpersonen außerhalb der EU
                        <br />
                        (netto plus 19% Kaution - wird zurück erstattet)
                      </p>
                    </div>
                    <div className="col">
                      <p>Vorab benötigte Dokumente:</p>
                      <hr />
                      <p>Firmenregistrierung, Ausweis Firmeninhaber</p>
                      <p>Ausweis des Kunden</p>
                      <p>Ausweis des Kunden, Firmenpapiere</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="font-weight-bold">
                        Hinweis für Zollkennzeichen: Nur von montags bis
                        freitags. Es wird Ihr original Ausweis benötigt. Für die
                        Ausstellung der Schilder am selben Tag müssen Sie bis 10
                        Uhr vormittags bei uns sein. Die Reservierung von
                        Fahrzeugen (max. für 1 Tag) ist nur schriftlich (eMail
                        an: info@pilatus.de) möglich.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* XS */}
            <div className="col-12 d-block d-md-none mb-3">
              <img src={Deutsch} className="mr-5" alt="Flagge Deutschland" />
            </div>
            <div className="col-12 d-block d-md-none">
              <p>
                Wir verkaufen an:
                <br />
                - Firmen innerhalb der EU (netto)
                <br />
                - Privatpersonen innerhalb der EU (plus Mehrwertsteuer)
                <br />- Firmen und Privatpersonen außerhalb der EU (netto plus
                19% Kaution - wird zurück erstattet)
              </p>
            </div>
            <div className="col-12 d-block d-md-none">
              <p>
                Vorab benötigte Dokumente:
                <br />
                - Firmenregistrierung, Ausweis Firmeninhaber
                <br />
                - Ausweis des Kunden
                <br />- Ausweis des Kunden, Firmenpapiere
              </p>
            </div>
            <div className="col-12 d-block d-md-none">
              <p className="font-weight-bold">
                Hinweis für Zollkennzeichen: Nur von montags bis freitags. Es
                wird Ihr original Ausweis benötigt. Für die Ausstellung der
                Schilder am selben Tag müssen Sie bis 10 Uhr vormittags bei uns
                sein. Die Reservierung von Fahrzeugen (max. für 1 Tag) ist nur
                schriftlich (eMail an: info@pilatus.de) möglich.
              </p>
            </div>
          </div>

          {/* ENGLISH */}
          {/* > XS */}
          <div className="row pt-5 pb-5 d-none d-md-block">
            <div className="col">
              <div className="media">
                <img src={UK} className="mr-5" alt="Flagge UK" />
                <div className="media-body">
                  <div className="row">
                    <div className="col">
                      <p>We sell to:</p>
                      <hr />
                      <p>
                        EU located companies (net of VAT)
                        <br />
                        <span style={{ color: '#000000' }}>PLACEHOLDER</span>
                      </p>

                      <p>EU located consumers (plus VAT)</p>
                      <p>
                        Outside EU companies and consumers
                        <br />
                        (net plus 19% refundable caution)
                      </p>
                    </div>
                    <div className="col">
                      <p>Upfront needed documents:</p>
                      <hr />
                      <p>
                        Company registration documents, passport company
                        representative, VAT number.
                      </p>
                      <p>Buyers passport</p>
                      <p>
                        Buyers passport, company registration documents (for
                        companies)
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="font-weight-bold">
                        Information for 30days transfer plates: From Mon. to
                        Fri. only. Your original passport is required. For
                        issuing plates at the same day, it's necessary, that
                        your're at our place not later that 10 o clock in the
                        morning. Reservation of cars (for max. 1 day) only in
                        written format by eMail (info@pilatus.de) possible.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* XS */}
          <div className="row pt-2 pb-5  d-block d-md-none">
            <div className="col-12 mb-3">
              <img src={UK} className="mr-5" alt="Flagge Deutschland" />
            </div>
            <div className="col-12 d-block d-md-none">
              <p>
                We sell to:
                <br />
                - EU located companies (net of VAT)
                <br />
                - EU located consumers (plus VAT)
                <br />- Outside EU companies and consumers (net plus 19%
                refundable caution)
              </p>
            </div>
            <div className="col-12 d-block d-md-none">
              <p>
                Upfront needed documents:
                <br />
                - Company registration documents, passport company
                representative, VAT number.
                <br />
                - Buyers passport
                <br />- Buyers passport, company registration documents (for
                companies)
              </p>
            </div>
            <div className="col-12 d-block d-md-none">
              <p className="font-weight-bold">
                Information for 30days transfer plates: From Mon. to Fri. only.
                Your original passport is required. For issuing plates at the
                same day, it's necessary, that your're at our place not later
                that 10 o clock in the morning. Reservation of cars (for max. 1
                day) only in written format by eMail (info@pilatus.de) possible.
              </p>
            </div>
          </div>

          {/* SPAIN */}
          {/* > XS */}
          <div className="row pt-5 pb-5 d-none d-md-block">
            <div className="col">
              <div className="media">
                <img src={Spanisch} className="mr-5" alt="Flagge Spanien" />
                <div className="media-body">
                  <div className="row">
                    <div className="col">
                      <p>Vendemos a:</p>
                      <hr />
                      <p>
                        Empresas ubicadas en la UE (sin IVA)
                        <br />
                        <span style={{ color: '#000000' }}>PLACEHOLDER</span>
                      </p>
                      <p>Particulares dentro de la UE (más IVA)</p>
                      <p>
                        Empresas y particulares fuera de la UE
                        <br />
                        (neto más el 19% de depósito reembolsable)
                      </p>
                    </div>
                    <div className="col">
                      <p>Documentos requeridos con antelación:</p>
                      <hr />
                      <p>
                        Registro de la empresa, documento de identidad del
                        representante de la empresa, NIF intracomunitario
                      </p>
                      <p>Documento de identidad del cliente</p>
                      <p>
                        Documento de identidad del cliente, documentos de la
                        empresa
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="font-weight-bold">
                        Información sobre las placas de aduanas: solo de lunes a
                        viernes. Se requiere su documento de identidad original.
                        Para emitir las placas el mismo día, es necesario que
                        esté en nuestra oficina antes de las 10 de la mañana. La
                        reserva de coches (para un máximo de 1 día) solo puede
                        realizarse por escrito por correo electrónico
                        (info@pilatus.de).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* XS */}
          <div className="row pt-2 pb-5  d-block d-md-none">
            <div className="col-12 mb-3">
              <img src={Spanisch} className="mr-5" alt="Flagge Deutschland" />
            </div>
            <div className="col-12 d-block d-md-none">
              <p>
                Vendemos a:
                <br />
                - Empresas ubicadas en la UE (sin IVA)
                <br />
                - Particulares dentro de la UE (más IVA)
                <br />- Empresas y particulares fuera de la UE (neto más el 19%
                de depósito reembolsable)
              </p>
            </div>
            <div className="col-12 d-block d-md-none">
              <p>
                Documentos requeridos con antelación:
                <br />
                - Registro de la empresa, documento de identidad del
                representante de la empresa, NIF intracomunitario
                <br />
                - Documento de identidad del cliente
                <br />- Documento de identidad del cliente, documentos de la
                empresa
              </p>
            </div>
            <div className="col-12 d-block d-md-none">
              <p className="font-weight-bold">
                Información sobre las placas de aduanas: solo de lunes a
                viernes. Se requiere su documento de identidad original. Para
                emitir las placas el mismo día, es necesario que esté en nuestra
                oficina antes de las 10 de la mañana. La reserva de coches (para
                un máximo de 1 día) solo puede realizarse por escrito por correo
                electrónico (info@pilatus.de).
              </p>
            </div>
          </div>

          {/* CROATIAN */}
          {/* > XS */}
          <div className="row pt-5 pb-5 d-none d-md-block">
            <div className="col">
              <div className="media">
                <img src={Kroatisch} className="mr-5" alt="Flagge Kroatien" />
                <div className="media-body">
                  <div className="row">
                    <div className="col">
                      <p>Mi prodajemo vozila:</p>
                      <hr />
                      <p>
                        Pravnim licima (firme) koje imaju svoje sjediste unutar
                        Europske Zajednice (EG); netto , bez placanja MWST, VAT,
                        PDV-a.
                      </p>
                      <p>
                        Privatnim licima koje imaju svoje mesto prebivalista
                        unutar Evropske Zajednice (oniplacaju neto cjenu plus
                        MWST, VAT ili PDV) tj.brutto cjenu.
                      </p>
                      <p>
                        Pravnim licima cije se sjediste nalazi van granica
                        Evropske Zajednice zemlje) tzv trece zemlje.
                        <br />
                        Ovi kupci placaju neto cjenu plus kauciju u iznosu od
                        19% koja se kupcu vraca nakon obavljenog izvoza.
                      </p>
                    </div>
                    <div className="col">
                      <p>Potrebna dokumentacija:</p>
                      <hr />
                      <p>
                        Registracija firme, pasos/putovnica vlasnika firme (ili
                        poslovodje, ili ovlascenog lica), identifikacioni broj
                        firme (VAT Nummber, MWST-ID Nr.)
                      </p>
                      <p>
                        Vazeca putna isprava kupcaPrivatnim licima koje imaju
                        svoje mesto prebivalista unutar Evropske Zajednice (oni
                        placaju neto cjenu plus MWST, VAT ili PDV) tj.brutto
                        cjenu. Potrebna dokumentacija: vazeca putna isprava
                        kupca
                      </p>
                      <p>
                        Vazeca putna isprava kupca , registracija firmeVazeca
                        putna isprava kupca , registracija firme
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="font-weight-bold">
                        Napomena kod registracije automobila za izvoz (tzv.col
                        tablice): Ove tablice imaju rok vaznosti 30 dana . Mogu
                        se dobiti samo radnim danima (od ponedeljka do petka).
                        Osoba na koju se registruje auto mora biti najkasnije do
                        10,00 sati ujutro kod nas. Subotom i nedeljom nije
                        moguce dobiti col tablice. Rezervacija vozila je moguca,
                        ali samo 24 sata pre dolaska po vozilo. Rezervacija se
                        mora najaviti u pismenoj formi preko naseg eMejla
                        info@pilatus.de
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* XS */}
          <div className="row pt-2 pb-5  d-block d-md-none">
            <div className="col-12 mb-3">
              <img src={Kroatisch} className="mr-5" alt="Flagge Deutschland" />
            </div>
            <div className="col-12 d-block d-md-none">
              <p>
                Mi prodajemo vozila:
                <br />
                - Pravnim licima (firme) koje imaju svoje sjediste unutar
                Europske Zajednice (EG); netto , bez placanja MWST, VAT, PDV-a.
                <br />
                - Privatnim licima koje imaju svoje mesto prebivalista unutar
                Evropske Zajednice (oniplacaju neto cjenu plus MWST, VAT ili
                PDV) tj.brutto cjenu.
                <br />- Pravnim licima cije se sjediste nalazi van granica
                Evropske Zajednice zemlje) tzv trece zemlje. Ovi kupci placaju
                neto cjenu plus kauciju u iznosu od 19% koja se kupcu vraca
                nakon obavljenog izvoza.
              </p>
            </div>
            <div className="col-12 d-block d-md-none">
              <p>
                Potrebna dokumentacija:
                <br />
                - Registracija firme, pasos/putovnica vlasnika firme (ili
                poslovodje, ili ovlascenog lica), identifikacioni broj firme
                (VAT Nummber, MWST-ID Nr.)Registracija firme, pasos/putovnica
                vlasnika firme (ili poslovodje, ili ovlascenog lica),
                identifikacioni broj firme (VAT Nummber, MWST-ID Nr.)
                <br />
                - Vazeca putna isprava kupcaPrivatnim licima koje imaju svoje
                mesto prebivalista unutar Evropske Zajednice (oni placaju neto
                cjenu plus MWST, VAT ili PDV) tj.brutto cjenu. Potrebna
                dokumentacija: vazeca putna isprava kupca
                <br />- Vazeca putna isprava kupca , registracija firmeVazeca
                putna isprava kupca , registracija firme
              </p>
            </div>
            <div className="col-12 d-block d-md-none">
              <p className="font-weight-bold">
                Napomena kod registracije automobila za izvoz (tzv.col tablice):
                Ove tablice imaju rok vaznosti 30 dana . Mogu se dobiti samo
                radnim danima (od ponedeljka do petka). Osoba na koju se
                registruje auto mora biti najkasnije do 10,00 sati ujutro kod
                nas. Subotom i nedeljom nije moguce dobiti col tablice.
                Rezervacija vozila je moguca, ali samo 24 sata pre dolaska po
                vozilo. Rezervacija se mora najaviti u pismenoj formi preko
                naseg eMejla info@pilatus.de
              </p>
            </div>
          </div>

          {/* ROMANIAN */}
          {/* > XS */}
          <div className="row pt-5 pb-5 d-none d-md-block">
            <div className="col">
              <div className="media">
                <img src={Rumaenisch} className="mr-5" alt="Flagge Rumänien" />
                <div className="media-body">
                  <div className="row">
                    <div className="col">
                      <p>Vindem către:</p>
                      <hr />
                      <p>
                        Companii situate în UE (fără TVA)
                        <br />
                        <span style={{ color: '#000000' }}>PLACEHOLDER</span>
                      </p>
                      <p>Consumatori situați în UE (cu TVA)</p>
                      <p>
                        Companii și consumatori din afara UE
                        <br />
                        (garanție rambursabilă fără și cu 19 %)
                      </p>
                    </div>
                    <div className="col">
                      <p>Documente necesare imediat:</p>
                      <hr />
                      <p>
                        Documentele de înregistrare ale companiei, pașaportul
                        reprezentantului companiei, codul fiscal
                      </p>
                      <p>Pașaportul cumpărătorilor</p>
                      <p>
                        Pașaportul cumpărătorilor, documentele de înregistrare
                        ale companiei (pentru companii)
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="font-weight-bold">
                        Informații pentru plăcuțele de înmatriculare provizorii
                        de 30 zile: Numai de luni până vineri. Este necesar
                        pașaportul dumneavoastră în original. Pentru emiterea
                        plăcuțelor în aceeași zi, trebuie să veniți la sediul
                        nostru cel târziu până la ora 10. Rezervarea mașinilor
                        (pentru cel mult 1 zi) se poate face numai în scris prin
                        e-mail (info@pilatus.de).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* XS */}
          <div className="row pt-2 pb-5  d-block d-md-none">
            <div className="col-12 mb-3">
              <img src={Rumaenisch} className="mr-5" alt="Flagge Deutschland" />
            </div>
            <div className="col-12 d-block d-md-none">
              <p>
                Vindem către:
                <br />
                - Companii situate în UE (fără TVA)
                <br />
                - Consumatori situați în UE (cu TVA)
                <br />- Companii și consumatori din afara UE (garanție
                rambursabilă fără și cu 19 %)
              </p>
            </div>
            <div className="col-12 d-block d-md-none">
              <p>
                Documente necesare imediat:
                <br />
                - Documentele de înregistrare ale companiei, pașaportul
                reprezentantului companiei, codul fiscal
                <br />
                - Pașaportul cumpărătorilor
                <br />- Pașaportul cumpărătorilor, documentele de înregistrare
                ale companiei (pentru companii)
              </p>
            </div>
            <div className="col-12 d-block d-md-none">
              <p className="font-weight-bold">
                Informații pentru plăcuțele de înmatriculare provizorii de 30
                zile: Numai de luni până vineri. Este necesar pașaportul
                dumneavoastră în original. Pentru emiterea plăcuțelor în aceeași
                zi, trebuie să veniți la sediul nostru cel târziu până la ora
                10. Rezervarea mașinilor (pentru cel mult 1 zi) se poate face
                numai în scris prin e-mail (info@pilatus.de).
              </p>
            </div>
          </div>

          {/* RUSSIAN */}
          {/* > XS */}
          <div className="row pt-5 pb-5 d-none d-md-block">
            <div className="col">
              <div className="media">
                <img src={Russisch} className="mr-5" alt="Flagge Russland" />
                <div className="media-body">
                  <div className="row">
                    <div className="col">
                      <p>Мы работаем с:</p>
                      <hr />
                      <p>
                        Юридическими лицами на территории ЕС (без НДС)
                        <br />
                        <span style={{ color: '#000000' }}>PLACEHOLDER</span>
                        <br />
                        <span style={{ color: '#000000' }}>PLACEHOLDER</span>
                        <br />
                        <span style={{ color: '#000000' }}>PLACEHOLDER</span>
                      </p>
                      <p>Физическими лицами на территории ЕС (с НДС)</p>
                      <p>
                        Юридическими и физическими лицами за пределами ЕС
                        <br />
                        (цена без НДС + 19% возвратный депозит)
                      </p>
                    </div>
                    <div className="col">
                      <p>Необходимые документы:</p>
                      <hr />
                      <p>
                        регистрационные документы, паспорт представителя
                        компании, идентификационный номер плательщика
                        НДСрегистрационные документы, паспорт представителя
                        компании, идентификационный номер плательщика НДС
                      </p>
                      <p>паспорт</p>
                      <p>паспорт, регистрационные документы (для юр. лиц)</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="font-weight-bold">
                        Оформление регистрационных номеров для вывоза автомобиля
                        за пределы Германии (действуют 30 дней): только с
                        понедельника по пятницу. Необходим оригинал паспорта.
                        Для оформления номеров в день обращения необходимо
                        прийти в салон не позднее 10 утра. Зарезервировать
                        автомобиль (макс. на 1 день) можно только по почте
                        (info@pilatus.de).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* XS */}
          <div className="row pt-2 pb-5  d-block d-md-none">
            <div className="col-12 mb-3">
              <img src={Russisch} className="mr-5" alt="Flagge Deutschland" />
            </div>
            <div className="col-12 d-block d-md-none">
              <p>
                Мы работаем с:
                <br />
                - Юридическими лицами на территории ЕС (без НДС)
                <br />
                - Физическими лицами на территории ЕС (с НДС)
                <br />- Юридическими и физическими лицами за пределами ЕС (цена
                без НДС + 19% возвратный депозит)
              </p>
            </div>
            <div className="col-12 d-block d-md-none">
              <p>
                Необходимые документы:
                <br />
                - регистрационные документы, паспорт представителя компании,
                идентификационный номер плательщика НДСрегистрационные
                документы, паспорт представителя компании, идентификационный
                номер плательщика НДС
                <br />
                - паспорт
                <br />- паспорт, регистрационные документы (для юр. лиц)
              </p>
            </div>
            <div className="col-12 d-block d-md-none">
              <p className="font-weight-bold">
                Оформление регистрационных номеров для вывоза автомобиля за
                пределы Германии (действуют 30 дней): только с понедельника по
                пятницу. Необходим оригинал паспорта. Для оформления номеров в
                день обращения необходимо прийти в салон не позднее 10 утра.
                Зарезервировать автомобиль (макс. на 1 день) можно только по
                почте (info@pilatus.de).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default International;
