import React from 'react';
import { Link } from 'react-router-dom';

import Car from '../assets/pilatus-car.gif';

const Footer = () => {
  return (
    <div className="container-fluid Footer">
      <div className="row">
        <div className="col-md-3">
          <h4>ABOUT US</h4>
          <p className="mt-4">
            Pilatus GmbH
            <br />
            Fahrzeughandel seit über 25 Jahren.
          </p>
          <p className="mt-4">
            Wir sprechen Deutsch, Englisch, Spanisch, Kroatisch, Russisch und
            Rumänisch.
          </p>
        </div>
        <div className="col-md-3">
          <h4 style={{ color: '#000000' }}>PLACEHOLDER</h4>
          <p className="mt-4">
            <i className="fas fa-phone-alt" /> +49 69 95 92 80 13
          </p>
          <p>
            <i className="fas fa-envelope" /> info@pilatus.de
          </p>
          <p>
            <i className="fas fa-map-marker-alt" /> Schmidtstr. 45
          </p>
          <p>
            <i className="fas fa-map-marker-alt" style={{ color: '#000000' }} />{' '}
            60326 Frankfurt am Main
          </p>
          <p>
            <i className="fas fa-map-marker-alt" style={{ color: '#000000' }} />{' '}
            Deutschland / Germany
          </p>
        </div>
        <div className="col-md-3">
          <h4>DEPARTMENTS</h4>
          <p className="mt-4">
            <Link to="/">Pilatus</Link>
            <br />
            <Link to="/fahrzeugbestand">Fahrzeugbestand</Link>
            <br />
            <Link to="/privatkunden">Privatkunden Finanzierung</Link>
            <br />
            <Link to="/anfahrt">Anfahrt</Link>
          </p>
          <p className="mt-4">
            <Link to="/Impressum">Impressum</Link> |{' '}
            <Link to="/datenschutz">Datenschutz</Link>
          </p>
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <img src={Car} alt="Pilatus Car" />
        </div>
      </div>
      <hr style={{ borderColor: 'rgba(119, 119, 119, 0.5)' }} />
      <div className="row">
        <div className="col text-center">
          <small>
            &copy; 2020 Pilatus GmbH. All rights reserved. Designed by{' '}
            <a
              href="https://dauthdesign.de"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#ffffff' }}
            >
              dauth Design
            </a>
            , Technical implementation by{' '}
            <a
              href="https://blackforestcode.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#ffffff' }}
            >
              BLACK FOREST CODE
            </a>
          </small>
        </div>
      </div>
    </div>
  );
};

export default Footer;
