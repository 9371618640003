import React from 'react';

import Header from './Header';
import Background from '../assets/Sportwagen.jpg';

const Anfahrt = () => {
  return (
    <div>
      <Header backgroundimage={Background}>
        <p className="Header-text">
          +49 69 95 92 80 13
          <br />
          E-MAIL: INFO@PILATUS.DE
        </p>
      </Header>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="map-responsive">
              <iframe
                title="Google Map"
                className="embed-responsive-item"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2559.110947161518!2d8.615242316066974!3d50.10293027942895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd0eb56a29fb0d%3A0xa942d65c5d255bfb!2sPilatus%20GmbH!5e0!3m2!1sde!2sde!4v1592555225319!5m2!1sde!2sde"
                frameBorder="0"
                style={{ border: '0' }}
                allowFullScreen
              >
                <p>
                  Ihr Browser kann leider keine eingebetteten Frames anzeigen.
                </p>
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Anfahrt;
