import React from 'react';

import Header from './Header';
import Background from '../assets/Tacho_Pilatus.jpg';

const Impressum = () => {
  return (
    <div>
      <Header backgroundimage={Background}>
        <p className="Header-text">
          +49 69 95 92 80 13
          <br />
          E-MAIL: INFO@PILATUS.DE
        </p>
      </Header>
      <div className="container-fluid Privatkunden-cont border-bottom">
        <div className="container">
          <div className="row pt-5 pb-4">
            <div className="col">
              <a
                href="#german"
                class="text-white"
                style={{ borderBottom: '1px dotted #ffffff' }}
              >
                Impressum (Deutsch)
              </a>
              {' / '}
              <a
                href="#english"
                class="text-white"
                style={{ borderBottom: '1px dotted #ffffff' }}
              >
                Site Notice (English)
              </a>
            </div>
          </div>
          <div className="row pt-5 pb-4" id="german">
            <div className="col">
              <h1>Impressum</h1>
              <h2>Angaben gemäß § 5 TMG</h2>
              <p>
                PILATUS GmbH
                <br />
                Schmidtstraße 45
                <br />
                60326 Frankfurt am Main
              </p>
              <p>
                Handelsregister: HRB 92463
                <br />
                Registergericht: Amtsgericht Frankfurt
              </p>
              <p>
                <strong>Vertreten durch:</strong>
                <br />
                Dean und Marian Pilatus
                <br />
                Dean Pilatus
                <br />
                Marian Pilatus
              </p>
              <h2>Kontakt</h2>
              <p>
                Telefon: +49 69 95 92 80 13
                <br />
                E-Mail: info@pilatus.de
              </p>
              <h2>Umsatzsteuer-ID</h2>
              <p>
                Umsatzsteuer-Identifikationsnummer gemäß § 27 a
                Umsatzsteuergesetz:
                <br />
                DE279950033
              </p>
              <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h2>
              <p>
                Dean und Marian Pilatus
                <br />
                Schmidtstraße 45
                <br />
                60326 Frankfurt am Main
              </p>
              <h2>EU-Streitschlichtung</h2>
              <p>
                Die Europäische Kommission stellt eine Plattform zur
                Online-Streitbeilegung (OS) bereit:{' '}
                <a
                  href="https://ec.europa.eu/consumers/odr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://ec.europa.eu/consumers/odr
                </a>
                .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
              </p>
              <h2>
                Verbraucher­streit­beilegung/Universal­schlichtungs­stelle
              </h2>
              <p>
                Wir sind nicht bereit oder verpflichtet, an
                Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen.
              </p>
              <h3>Haftung für Inhalte</h3>{' '}
              <p>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                gespeicherte fremde Informationen zu überwachen oder nach
                Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                hinweisen.
              </p>{' '}
              <p>
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                Informationen nach den allgemeinen Gesetzen bleiben hiervon
                unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
                Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
                Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden
                wir diese Inhalte umgehend entfernen.
              </p>{' '}
              <h3>Haftung für Links</h3>{' '}
              <p>
                Unser Angebot enthält Links zu externen Websites Dritter, auf
                deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
                diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                Verlinkung nicht erkennbar.
              </p>{' '}
              <p>
                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
                jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                derartige Links umgehend entfernen.
              </p>{' '}
              <h3>Urheberrecht</h3>{' '}
              <p>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten,
                nicht kommerziellen Gebrauch gestattet.
              </p>{' '}
              <p>
                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
                wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
                werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                von Rechtsverletzungen werden wir derartige Inhalte umgehend
                entfernen.
              </p>
            </div>
          </div>
          <hr style={{ borderColor: '#ffffff' }} />
          <div className="row pt-5 pb-4" id="english">
            <div className="col">
              <h1>Site Notice</h1>
              <h2>
                Information pursuant to Sect. 5 German Telemedia Act (TMG)
              </h2>
              <p>
                PILATUS GmbH
                <br />
                Schmidtstraße 45
                <br />
                60326 Frankfurt am Main
              </p>
              <p>
                Commercial Register: HRB 92463
                <br />
                Registration court: Amtsgericht Frankfurt
              </p>
              <p>
                <strong>Represented by:</strong>
                <br />
                Dean Pilatus
                <br />
                Marian Pilatus
              </p>
              <h2>Contact</h2>
              <p>
                Phone: +49 69 95 92 80 13
                <br />
                E-mail: info@pilatus.de
              </p>
              <h2>VAT ID</h2>
              <p>
                Sales tax identification number according to Sect. 27 a of the
                Sales Tax Law:
                <br />
                DE279950033
              </p>
              <h2>
                Responsible for the content according to Sect. 55, paragraph 2
                RStV
              </h2>
              <p>
                Dean and Marian Pilatus
                <br />
                Schmidtstraße 45
                <br />
                60326 Frankfurt am Main
              </p>
              <h2>EU dispute resolution</h2>
              <p>
                The European Commission provides a platform for online dispute
                resolution (ODR):{' '}
                <a
                  href="https://ec.europa.eu/consumers/odr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://ec.europa.eu/consumers/odr
                </a>
                .<br /> Our e-mail address can be found above in the site
                notice.
              </p>
              <h2>
                Dispute resolution proceedings in front of a consumer
                arbitration board
              </h2>
              <p>
                We are not willing or obliged to participate in dispute
                resolution proceedings in front of a consumer arbitration board.
              </p>
              <h3>Liability for Contents</h3>{' '}
              <p>
                As service providers, we are liable for own contents of these
                websites according to Paragraph 7, Sect. 1 German Telemedia Act
                (TMG). However, according to Paragraphs 8 to 10 German Telemedia
                Act (TMG), service providers are not obligated to permanently
                monitor submitted or stored information or to search for
                evidences that indicate illegal activities.
              </p>{' '}
              <p>
                Legal obligations to removing information or to blocking the use
                of information remain unchallenged. In this case, liability is
                only possible at the time of knowledge about a specific
                violation of law. Illegal contents will be removed immediately
                at the time we get knowledge of them.
              </p>{' '}
              <h3>Liability for Links</h3>{' '}
              <p>
                Our offer includes links to external third-party websites. We
                have no influence on the contents of those websites, therefore
                we cannot guarantee for those contents. Providers or
                administrators of linked websites are always responsible for
                their own contents.
              </p>{' '}
              <p>
                The linked websites had been checked for possible violations of
                law at the time of the establishment of the link. Illegal
                contents were not detected at the time of the linking. A
                permanent monitoring of the contents of linked websites cannot
                be imposed without reasonable indications that there has been a
                violation of law. Illegal links will be removed immediately at
                the time we get knowledge of them.
              </p>{' '}
              <h3>Copyright</h3>{' '}
              <p>
                Contents and compilations published on these websites by the
                providers are subject to German copyright laws. Reproduction,
                editing, distribution as well as the use of any kind outside the
                scope of the copyright law require a written permission of the
                author or originator. Downloads and copies of these websites are
                permitted for private use only.
                <br /> The commercial use of our contents without permission of
                the originator is prohibited.
              </p>{' '}
              <p>
                Copyright laws of third parties are respected as long as the
                contents on these websites do not originate from the provider.
                Contributions of third parties on this site are indicated as
                such. However, if you notice any violations of copyright law,
                please inform us. Such contents will be removed immediately.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Impressum;
