import React, { useState } from 'react';

import Deutschland from '../assets/Fahnen/Deutsch.jpg';
import UK from '../assets/Fahnen/UK.jpg';
import Spanien from '../assets/Fahnen/Spanisch.jpg';
import Kroatien from '../assets/Fahnen/Kroatisch.jpg';
import Rumaenien from '../assets/Fahnen/Rumaenisch.jpg';
import Russland from '../assets/Fahnen/Russisch.jpg';

const languages = {
  german: {
    title: 'FAHRZEUGHANDEL SEIT ÜBER 25 JAHREN.',
    text:
      'Wir sprechen Deutsch, Englisch, Spanisch, Kroatisch, Russisch und Rumänisch.',
  },
  english: {
    title: 'VEHICLE TRADING FOR OVER 25 YEARS.',
    text: 'We speak German, English, Spanish, Croatian, Russian and Romanian.',
  },
  spanish: {
    title: 'COMERCIO DE VEHÍCULOS POR MÁS DE 25 AÑOS.',
    text: 'Hablamos alemán, inglés, español, croata, ruso y rumano.',
  },
  croatian: {
    title: 'TRGOVINA VOZILOM VEĆIH 25 GODINA.',
    text:
      'Govorimo njemački, engleski, španjolski, hrvatski, ruski i rumunjski.',
  },
  romanian: {
    title: 'COMERCIALIZAREA VEHICULUI PENTRU PESTE 25 DE ANI',
    text: 'Vorbim germană, engleză, spaniolă, croată, rusă și română.',
  },
  russian: {
    title: 'ТОРГОВЛЯ ТРАНСПОРТНЫМИ СРЕДСТВАМИ ЗА 25 ЛЕТ.',
    text:
      'Мы говорим на немецком, английском, испанском, хорватском, русском и румынском языках.',
  },
};

const HomeJumbo = () => {
  const [lang, setLang] = useState('german');

  const setLanguage = (e) => {
    setLang(e.target.id);
  };

  const setDefaultLanguage = () => {
    setLang('german');
  };

  return (
    <div className="Home-jumbotron">
      <div className="row text-center">
        <div className="col-2">
          <img
            src={Deutschland}
            alt="Flagge Deutschland"
            id="german"
            onMouseEnter={setLanguage}
            onMouseLeave={setDefaultLanguage}
          />
        </div>
        <div className="col-2">
          <img
            src={UK}
            alt="Flagge UK"
            id="english"
            onMouseEnter={setLanguage}
            onMouseLeave={setDefaultLanguage}
          />
        </div>
        <div className="col-2">
          <img
            src={Spanien}
            alt="Flagge Spanien"
            id="spanish"
            onMouseEnter={setLanguage}
            onMouseLeave={setDefaultLanguage}
          />
        </div>
        <div className="col-2">
          <img
            src={Kroatien}
            alt="Flagge Kroatien"
            id="croatian"
            onMouseEnter={setLanguage}
            onMouseLeave={setDefaultLanguage}
          />
        </div>
        <div className="col-2">
          <img
            src={Rumaenien}
            alt="Flagge Rumaenien"
            id="romanian"
            onMouseEnter={setLanguage}
            onMouseLeave={setDefaultLanguage}
          />
        </div>
        <div className="col-2">
          <img
            src={Russland}
            alt="Flagge Russland"
            id="russian"
            onMouseEnter={setLanguage}
            onMouseLeave={setDefaultLanguage}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col">
          <h1>
            PILATUS GMBH
            <br />
            {languages[lang]['title']}
          </h1>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-8 offset-md-4">
          <h2>MO - FR 9:00-18:00 + SA 9:00-14:00</h2>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col">
          <h3>{languages[lang]['text']}</h3>
        </div>
      </div>
    </div>
  );
};

export default HomeJumbo;
