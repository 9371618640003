import React, { useRef, useEffect } from 'react';

import TheVideo from '../assets/Pilatus_Autos.mp4';
import Poster from '../assets/Poster.jpg';

const Video = () => {
  useEffect(() => {
    document.getElementById('stop').style.display = 'none';
  }, []);

  const videoRef = useRef(null);

  const handleVideoPlay = () => {
    videoRef.current.play();
    document.getElementById('play').style.display = 'none';
    document.getElementById('stop').style.display = 'initial';
    document
      .getElementById('pilatus-video')
      .addEventListener('ended', myHandler, false);
    function myHandler(e) {
      document.getElementById('play').style.display = 'initial';
      document.getElementById('stop').style.display = 'none';
      videoRef.current.load();
    }
  };

  const handleVideoStop = () => {
    videoRef.current.pause();
    document.getElementById('stop').style.display = 'none';
    document.getElementById('play').style.display = 'initial';
  };

  return (
    <div className="row">
      <div className="col">
        <div className="embed-responsive embed-responsive-16by9">
          <video
            id="pilatus-video"
            ref={videoRef}
            className="thevideo"
            preload="none"
            poster={Poster}
            controls={false}
          >
            <source src={TheVideo} />
          </video>
          <div className="Home-video-overlay">
            <div>
              <i
                className="fab fa-youtube text-dark"
                onClick={handleVideoPlay}
                id="play"
                //style={{ color: 'rgba(0, 69, 144, .5)' }}
              />
              <i
                className="fas fa-pause text-secondary stop-btn"
                onClick={handleVideoStop}
                id="stop"
                //style={{ color: 'rgba(104, 104, 104, .2)' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
