import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';

import Navbar from '../src/components/Navbar';
import Home from '../src/components/Home';
import Fahrzeugbestand from '../src/components/Fahrzeugbestand';
import International from '../src/components/International';
import Privatkunden from '../src/components/Privatkunden';
import Anfahrt from '../src/components/Anfahrt';
import Impressum from '../src/components/Impressum';
import Datenschutz from '../src/components/Datenschutz';
import Footer from '../src/components/Footer';

function App() {
  return (
    <Router>
      <Navbar />
      <div className="container-fluid">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/fahrzeugbestand" component={Fahrzeugbestand} />
          <Route
            exact
            path="/internationale-kunden"
            component={International}
          />
          <Route exact path="/privatkunden" component={Privatkunden} />
          <Route exact path="/anfahrt" component={Anfahrt} />
          <Route exact path="/impressum" component={Impressum} />
          <Route exact path="/datenschutz" component={Datenschutz} />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
