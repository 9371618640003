import React from 'react';

const Header = (props) => {
  return (
    <div
      className="row Header-row"
      style={{ backgroundImage: `url(${props.backgroundimage})` }}
    >
      <div className="col">
        <div className="Header-col">{props.children}</div>
      </div>
    </div>
  );
};

export default Header;
