import React from 'react';

import Header from './Header';
import Background from '../assets/Mercedes.jpg';

const Fahrzeugbestand = () => {
  return (
    <div>
      <Header backgroundimage={Background}>
        <p className="Header-text">
          +49 69 95 92 80 13
          <br />
          E-MAIL: INFO@PILATUS.DE
        </p>
      </Header>
      <div className="row m-1" style={{ minHeight: '100vh' }}>
        <div className="col">
          <div
            className="embed-responsive embed-responsive-1by1"
            style={{ height: '100%' }}
          >
            <iframe
              title="Unser Fahrzeugbestand"
              className="embed-responsive-item"
              src="https://home.mobile.de/home/index.html?partnerHead=false&amp;colorTheme=default&amp;customerId=6836259#ses"
            >
              <p>
                Ihr Browser kann leider keine eingebetteten Frames anzeigen: Sie
                k&ouml;nnen die eingebettete Seite &uuml;ber den folgenden
                Verweis aufrufen:{' '}
                <a href="http://home.mobile.de/home/index.html?partnerHead=false&amp;colorTheme=default&amp;customerId=6836259#ses">
                  Seite öffnen
                </a>
              </p>
            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fahrzeugbestand;
