import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

import Logo from '../assets/pilatus.svg';

const Header = () => {
  return (
    <Navbar expand="lg" className="MyNavbar">
      <Navbar.Brand href="/" className="navbar-brand-small pt-4 pb-4">
        <img src={Logo} alt="Pilatus Logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="m-auto">
          <Nav.Link
            href="/fahrzeugbestand"
            className="MyNavbar-nav-link text-center"
          >
            <span>FAHRZEUGBESTAND</span>
            <hr style={{ borderColor: '#ffffff' }} />
            <span>CARS ON STOCK</span>
          </Nav.Link>
          <Nav.Link
            href="/internationale-kunden"
            className="MyNavbar-nav-link text-center"
          >
            <span>INTERNATIONALE KUNDEN</span>
            <hr style={{ borderColor: '#ffffff' }} />
            <span>INTERNATIONAL CUSTOMERS</span>
          </Nav.Link>
          <Navbar.Brand href="/" className="text-center navbar-brand-md">
            <img src={Logo} alt="Pilatus Logo" />
          </Navbar.Brand>
          <Nav.Link
            href="/privatkunden"
            className="MyNavbar-nav-link text-center"
          >
            <span>PRIVATKUNDEN FINANZIERUNG</span>
            <hr style={{ borderColor: '#ffffff' }} />
            <span>CUSTOMER LOANS</span>
          </Nav.Link>
          <Nav.Link href="/anfahrt" className="MyNavbar-nav-link text-center">
            <span>ANFAHRT</span>
            <hr style={{ borderColor: '#ffffff' }} />
            <span>WAY TO US</span>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
