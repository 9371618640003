import React from 'react';
import { Link } from 'react-router-dom';

import Header from './Header';
import Background from '../assets/Lenkrad.jpg';
import PDF from '../assets/Fragebogen-Finanzierung_Privatkunden.pdf';

const Privatkunden = () => {
  return (
    <div>
      <Header backgroundimage={Background}>
        <p className="Header-text">
          +49 69 95 92 80 13
          <br />
          E-MAIL: INFO@PILATUS.DE
        </p>
      </Header>
      <div className="container-fluid Privatkunden-cont border-bottom">
        <div className="container">
          <div className="row pt-5 pb-4">
            <div className="col">
              <p className="font-weight-bold">
                Sie haben bei uns die Möglichkeit, über verschiedene Banken zu
                finanzieren. Wichtig dafür ist:
              </p>
              <ul>
                <li>Kein SCHUFA Eintrag</li>
                <li>Erstwohnsitz in Deutschland</li>
                <li>
                  Bei nicht-EU-Staatsbürgerschaft eine dauerhafte
                  Aufenthaltserlaubnis, bzw. eine Aufenthaltserlaubnis länger
                  als der Finanzierungszeitraum
                </li>
                <li>
                  Bei gewerblicher Finanzierung: Benötigen wir eine BWA nicht
                  älter als 12 Monate
                </li>
              </ul>
              <p>
                Auf dieser PDF finden Sie die notwendigen Unterlagen und
                Informationen für eine Finanzierung:
              </p>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col text-center">
              <Link
                to={PDF}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-lg"
                style={{ backgroundColor: '#F76937', color: '#ffffff' }}
              >
                Fragebogen Finanzierung Privatkunden (Deutsch){' '}
                <i className="fas fa-download" />
              </Link>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col">
              <p className="font-weight-bold">
                With us you have the option of using various banks finance. The
                important thing for this is:
              </p>
              <ul>
                <li>No SCHUFA entry</li>
                <li>Primary residence in Germany</li>
                <li>
                  For non-EU citizenship a permanent one Residence permit or a
                  residence permit longer than the funding period
                </li>
                <li>
                  For commercial financing: We do not need a BWA older than 12
                  months
                </li>
              </ul>
              <p>
                On this PDF you will find the necessary documents and
                Information for financing:
              </p>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col text-center">
              <Link
                to={PDF}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-lg"
                style={{ backgroundColor: '#F76937', color: '#ffffff' }}
              >
                Financing questionnaire for private customers (German){' '}
                <i className="fas fa-download" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privatkunden;
