import React from 'react';

import Header from './Header';
import Video from './Video';
import Background1 from '../assets/Startseite_Porsche.jpg';
import Background2 from '../assets/Tacho_Pilatus.jpg';
import HomeJumbo from './HomeJumbo';

const Home = () => {
  return (
    <div>
      <Header backgroundimage={Background1}>
        <p className="Header-text">
          +49 69 95 92 80 13
          <br />
          E-MAIL: INFO@PILATUS.DE
        </p>
      </Header>
      <Header backgroundimage={Background2}>
        <HomeJumbo />
      </Header>
      <Video />
    </div>
  );
};

export default Home;
